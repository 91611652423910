<template>
  <div class="fadein">
    <div class="mask"></div>
    <el-row class="login-content">
      <el-col :span="12" class="right-box">
		    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="0px" class="demo-ruleForm login-container">
		      <div class="logo-box">
		      	<img src="../assets/images/logo.png" class="logo-img" />
		      </div>
		      <el-form-item prop="username">
		        <el-input type="text" v-model="ruleForm.username" prefix-icon="el-icon-user" placeholder="باشقۇرغۇچى "></el-input>
		      </el-form-item>
		      <el-form-item prop="password">
            <el-tooltip effect="dark" content="大写锁定已打开" :manual="true" v-model="bigChar" placement="bottom">
              <el-input show-password v-model="ruleForm.password" @keyup.native="pwdKeyup" prefix-icon="el-icon-lock" placeholder="پارول"></el-input>  
            </el-tooltip>
		      </el-form-item>
          <el-form-item prop="captcha_code">
            <el-row>
              <el-col :span="11">
                <el-input type="text" @keyup.native.enter="handleSubmit" v-model="ruleForm.captcha_code" prefix-icon="el-icon-document-checked" placeholder="تەستىق كودى">
                </el-input>
              </el-col>
              <el-col :span="12" :offset="1" class="img-box">
                <img :src="captcha_image_content" @click="getCaptcha">
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="width:100%;margin-bottom: 0;">
            <el-button type="success" style="width:100%;" @click.native.prevent="handleSubmit" :loading="logining">كىرىش</el-button>
          </el-form-item>
		    </el-form>
      </el-col>
      <el-col :span="12" class="left-info">
      	<p class="dashboard-text rtl-right">دۇردانە تور دەرسخانىسى ئارقا سۇپا باشقۇرۇش سېستىمىسى  </p>
      	<ul class="rtl-right">
      		<li><i class="iconfont icon-check"></i><span>ئوقۇتقۇچى ئۇچۇرى باشقۇرۇش </span></li>
          <li><i class="iconfont icon-check"></i><span>دەرس  ئۇچۇرى باشقۇرۇش </span></li>
          <li><i class="iconfont icon-check"></i><span>ۋاكالەتچى   ئۇچۇرى باشقۇرۇش </span></li>
          <li><i class="iconfont icon-check"></i><span>ئىستاتىستىكا </span></li>
      	</ul>
      </el-col>
    </el-row>
    <div class="login-footer">
    	<img src="../assets/images/line.png" />
    	<p class="rtl-right">شىنجاڭ ھەمدەم ئېلېكتىرون پەن-تېخنىكا چەكلىك شىركىتى </p>
    </div>
  </div>
</template>

<script>
	var self;

  export default {
  	activated(){
  		self = this;
      self.$refs.ruleForm.resetFields();
      self.getCaptcha();
  	},
    data() {
      return {
        logining: false,
        ruleForm: {
          username: '',
          password: '',
          captcha_key:'',
          captcha_code: ''
        },
        captcha_image_content:"",
        rules: {
          username: [
            { required: true, message: 'باشقۇرغۇچى نامىنى كىرگۈزۈڭ ', trigger: 'blur' }
          ],
          password: [
            { required: true, message: 'پارول كىرگۈزۈڭ ', trigger: 'blur' }
          ],
          captcha_code: [
            { required: true, message: 'تەستىق كودىنى كىرگۈزۈڭ ', trigger: 'blur' }
          ]
        },
        firstTochar:false,
        bigChar:false
      };
    },
    methods: {
      pwdKeyup(event){
        //是否输入过字母键，且判断是否按键为caps lock
        if(self.firstTochar){
            if(event.keyCode===20){
              self.bigChar=!self.bigChar;
              return;
            }
        }
        //未输入过字母键，或按键不是caps lock，判断每次最后输入的字符的大小写
        var e = event||window.event;
        var keyvalue = e.keyCode ? e.keyCode : e.which;
        var shifKey = e.shiftKey ? e.shiftKey:((keyvalue == 16) ? true : false);
        if(typeof(self.ruleForm.password)==='undefined'){
          return;
        }
        var strlen = self.ruleForm.password.length;
        var password=self.ruleForm.password;

        if(strlen){
          var uniCode =password.charCodeAt(strlen-1);
          if(keyvalue>=65 && keyvalue<=90){     
            //如果是字母键                    
            self.firstTochar=true;
            if(((uniCode >= 65 && uniCode <= 90) && !shifKey)||((uniCode >= 97 && uniCode <= 122) && shifKey)){
                self.bigChar=true;
            }else{
                self.bigChar=false;
            }
          }
        }
      },
      getCaptcha(){
        self.$post('common/captchas').then((response) => {
          if(response.status == 201){
            self.ruleForm.captcha_key = response.data.data.captcha_key;
            self.captcha_image_content = response.data.data.captcha_image_content;
          }else{
            self.$message({
              message:response.message,
              type: 'error'
            });
          }
        });
      },
      handleSubmit() {
        self.$refs.ruleForm.validate((valid) => {
          if (valid) {
            self.logining = true;

            self.$post('admin/authorizations',{
              username:self.ruleForm.username,
              password:self.ruleForm.password,
              captcha_key:self.ruleForm.captcha_key,
              captcha_code:self.ruleForm.captcha_code,
            }).then((response) => {
              console.log('--------------');
              console.log(response);
              if(response.status == 201){
                localStorage.setItem("token",response.data.data.api_token);
                localStorage.setItem("name",response.data.data.name);
                localStorage.setItem("phone",response.data.data.phone);
                self.$message({
                  message:response.data.message,
                  type: 'success'
                });
                self.$router.push({ path: '/home' });
              }else if(response.status == 401){
                self.$message({
                  message:response.message,
                  type: 'error'
                });
              }

              self.logining = false;
            }).catch(err => {
              self.getCaptcha(); //出现错误重新获取验证码
              self.ruleForm.captcha_code = "";

              self.logining = false;
            })

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
	.login-content{
		margin-top: 10%;
		// overflow: hidden;
		padding:0 20%;
		&:after{
			content: "";
			display: table;
			clear: both;
		}
		.left-info{
      position: relative;
      z-index: 99;
      text-align: right;
			.dashboard-text{
				color: #fff;
				font-size: 26px;
				padding-bottom: 20px;
			}
			ul{
				li{
					margin-bottom: 18px;
					.iconfont,span{
						color: #fff;
						font-size: 15px;
					}
					.iconfont{
						padding-left: 6px;
					}
				}
			}
		}
		.right-box{
		  .login-container {
		  	float: left;
		    position:relative;
		    z-index: 99;
		    -webkit-border-radius: 5px;
		    border-radius: 5px;
		    -moz-border-radius: 5px;
		    background-clip: padding-box;
		    width: 400px;
		    padding: 40px;
		    background: rgba(255,255,255,1);
		    box-shadow: 0 0 15px #cac6c6;
		    .title {
		      margin: 0px auto 40px auto;
		      text-align: center;
		      color: #505458;
		    }
		    .img-box{
		      text-align: right;
		      line-height: normal;
		      img{
		        cursor: pointer;
		        border-radius: 5px;
		      }
		    }
		    .logo-box{
		    	text-align: center;
		    	margin-bottom: 20px;
			    .logo-img{
			    	width: 300px;
			    	height: 90px;
			    }
		    }
		  }
		}
	}
  .fadein {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/back.jpg');
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-animation: fadein .5s ease-out 0s forwards;
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0
    }
    to {
      opacity: 1
    }
  }
  .mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.15);
    z-index: 5;
  }
  .login-footer{
  	position: fixed;
  	left: 0;
  	right: 0;
  	bottom: 0;
  	text-align: center;
  	padding: 20px 0;
    z-index: 6;
  	img{
  		position: absolute;
  		left: 0;
  		right: 0;
  		top: 0;
  	}
  	p{
  		color: #fff;
      font-size: 14px;
      text-align: center;
  	}
  }
  span.el-input__suffix {
    right: 5px !important;
    left: initial !important;
  }
</style>